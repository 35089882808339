import white_paper from "./images/elle_assets/elle_about.pdf";
import skyyler from "./images/elle_assets/Skyyler_Siejko.jpg";
import shreyas from "./images/elle_assets/Shreyas_Ramanujam.png";
import kristian from "./images/elle_assets/Kristian.jpeg";
import ambient_elle from "./images/elle_assets/ambient_elle.png";
import zgc_logo from "./images/elle_assets/zgc_logo.png";
import cube from "./images/elle_assets/elle_Rad_Cube_Logo.png";
import elle_crosseye from "./images/elle_assets/elle_crosseye.png";
import leaf_logo from "./images/elle_assets/leaf_logo.png";
import elle_laptop from "./images/elle_assets/media.png";
import glow_seed from "./images/elle_assets/glow_seed_icon.png";
import network_tree from "./images/elle_assets/elle_tree.png";
import art_square from "./images/elle_assets/art_square.png";
import content_studio from "./images/elle_assets/content_share.png";
import elle_chip from "./images/elle_assets/elle_chip.png";
import elle_full_seed from "./images/elle_assets/elle_full_open.png";
import elle_logo from "./images/elle_assets/elle_logo.png";
import helix_logo from "./images/elle_assets/helix_icon.png";
import how_works from "./images/elle_assets/How_It_Works_Desktop.png";
import OS_Ven from "./images/elle_assets/OS_Stack_Combined.png";
import OS_layer  from "./images/elle_assets/OS_Stack_Mobile.png";
import OS_layer_blank from "./images/elle_assets/OS_Stack_white.png";
import cube_home from "./images/elle_assets/cube_ad.png";
import elle_logo_small from "./images/elle_assets/elle_logo_small.png";







export const home_info = {
    slogan: "we have a strand for that",
    header: "Experience an Intuitive Optimization System...",
    subHeader: "An Optimized Computing Environment suited for ",
    subHeader_focus: "You and your community",
    system_descr: "elle is integrated directly with the OS  as a standalone system or an Environmental System (ES)",
    secure_header: "Anonymous & Secure Computer DNA",
    secure_subHeader: "elle only uses non-user specific data so it is safe to use across platforms and devices",
    alpha_header: "Help Build elle's Ecosystem!",
    alpha_subHeader: "welcome home.",
    currentRelease: 'elle.Pine.spring'
    
}

export const about_info = {
    main_intro: `We built a new platform. What we like to call a Natural Environmental System (NES) which combines technology and the fundamental principles of nature - 
    such as natural selection, mutation, and evolution - to create a new approach to software on devices and clouds. In short, we connect things to their environments.`, 
    sub_intro: ` We developed a brand new data type`,
    sub_intro_focus: `.dna`,
    sub_outro: `we believe this will be the foundation for a new field: Computer Biology`,
    mission: `To grow foundational understanding of nature's connection to technology; and to explore the importance of computer biology within a digital landscape.`,
    principles: [`1. Access to elle is free and always will be`, 
    `2. elle shall be treated as both a system and an idea.`, 
    `3. elle will only capture non-user specific data.`,
    `4. elle will always be used to help technology grow.`]
}

export const contact_info = {

    address: `4500 Great America Pkwy, Santa Clara, CA`,
    phone: `(847) 791-7997`,
    email: `elle@elledna.com`,
    website: `www.elledna.com`

}

export const alpha = {
    tokenPrompt: `Enter Your Token ID Below: `,
}

export const terms = {


statement: `YOU EXPLICITLY ACKNOWLEDGE, UNDERSTAND AND AGREE THAT ELLE
SYSTEMS INC AND OUR SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES,
AGENTS, PARTNERS AND LICENSORS SHALL NOT BE LIABLE TO YOU FOR ANY
PUNITIVE, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY
DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES WHICH MAY BE
RELATED TO THE LOSS OF ANY PROFITS, GOODWILL, USE, DATA AND/OR
OTHER INTANGIBLE LOSSES, EVEN THOUGH WE MAY HAVE BEEN ADVISED OF
SUCH POSSIBILITY THAT SAID DAMAGES MAY OCCUR, AND RESULT FROM:
a) THE USE OR INABILITY TO USE OUR SERVICE;
b) THE COST OF PROCURING SUBSTITUTE GOODS AND SERVICES;
c) UNAUTHORIZED ACCESS TO OR THE ALTERATION OF YOUR
TRANSMISSIONS AND/OR DATA;
d) STATEMENTS OR CONDUCT OF ANY SUCH THIRD PARTY ON OUR
SERVICE;
e) AND ANY OTHER MATTER WHICH MAY BE RELATED TO OUR SERVICE.
SECTION 14 - RELEASE
In the event you have a dispute, you agree to release Elle Systems Inc(and its officers,
directors, employees, agents, parent subsidiaries, affiliates, co-branders, partners and any other
third parties) from claims, demands and damages (actual and consequential) of every kind and
nature, known and unknown, suspected or unsuspected, disclosed and undisclosed, arising out
of or in any way connected to such dispute.
SECTION 15 - NOTICE
Elle Systems Inc may furnish you with notices, including those with regards to any changes to
the Terms, including but not limited to email, regular mail, MMS or SMS, text messaging,
postings on our website Services, or other reasonable means currently known or any which
may be herein after developed. Any such notices may not be received if you violate any
aspects of the Terms by accessing our Services in an unauthorized manner. Your acceptance of
this Agreement constitutes your agreement that you are deemed to have received any and all
notices that would have been delivered had you accessed our Services in an authorized
manner.`



}

export const socials = {
    facebook:"https://www.facebook.com/people/Elle-Systems-Inc/100076274272794/",
    instagram: "https://www.instagram.com/elle.dna/",
    twitter: "https://twitter.com/elle_dna",
    youtube: "https://www.youtube.com/channel/UC9SxlO2xeCeuOb-nIIhaYBQ",
    github: "https://github.com/elledna/",
    linkedin:"https://www.linkedin.com/company/elle-systems-inc",
    
}




export const logos = {
    elle_leaf: leaf_logo,
    zgc: zgc_logo,
    elle: elle_logo,
    elle_small:elle_logo_small,

}


export const media = {


    paper:white_paper,
  
    cube:cube, 
    eyes:elle_crosseye,
    laptop: elle_laptop,
    glow_seed: glow_seed,
    tree: network_tree,
    square: art_square,
    studio: content_studio,
    chip: elle_chip,
    promo_layout: elle_full_seed ,
    helix: helix_logo,
    how: how_works,
    ven: OS_Ven,
    layer: OS_layer,
    layer_blank: OS_layer_blank,
    ambient:ambient_elle,
    cube_home: cube_home,
    

}


export const AssociationsDetails = [
    {
      yearRange: "2021-present",
      title: "Ambient Scientific",
      place: "Santa Clara, CA",
      desc: "We work at integrating into their hardware",
      image:ambient_elle,
    },
        {
        yearRange: "2020 - 2022",
        title: "ZGC CORP.",
        place: "Santa Clara, CA",
        desc: "Office at Innovation Center",
        image:zgc_logo,
    
    },  
  
  ];


     
  export const techStack = [
    {
      name: "C",
      percent: 30,
    }, 
      {
      name: "Python",
      percent: 20,
    },
    {
        name: "C++",
        percent: 10,
      },


    {
      name: "Go",
      percent: 35,
    },
    {
      name: "React",
      percent: 5,
    },
    
  ];


  export const buildGoals = [
  
    {
      name: "Google Cloud Service Goal",
      percent: 100,
    },
    {
      name: "Hardware Integration Goal",
      percent: 35,
    },
    {
        name: "IoT Integration Goal",
        percent: 52,
      },
      {
        name: "Home Automation Goal",
        percent: 80,
      },
      {
        name: "open source Goal",
        percent: 50,
      },
  
  ];


  export const TeamDetails = [
    {
      yearRange: "present",
      title: "CEO & Founder",
      name:"Skyyler Siejko",
      desc: "Physics/DSP/Systems",
      languages:" C , go, C++, JS, Typescript, Python",
      image: skyyler,
    },
    {
      yearRange: "present",
      title: "CTO & Co-Founder",
      name: "Shreyas Ramanujam",
      desc: "Cloud/Network/Systems",
      languages:" C , go, C++, JS, Typescript, Python",
      image:shreyas,
    },
   
  
  ];

  export const services = [
    {
      name: "Ambient Engine Design",
      desc: "we can build a customized Engine for any need.",
      icon: "fas fa-pencil-ruler",
    },
    {
      name: " Ambient Device Awarness",
      desc: "the main purpose is to give your device awarness.",
      icon: "fas fa-desktop",
    },
    {
      name: "Home Automation",
      desc: "we can become the final OS for your home,growing with the family through generations. retaining how the home should work without the need for interaction",
      icon: "fas fa-pencil-ruler",
    },
    {
      name: "Universal Network Design",
      desc: "We are currently working on a universal network design that will be able to connect any device to elle.",
      icon: "fas fa-network-wired",
    },
    {
      name: "Research & Development",   
      desc: "ideas are the lifeblood of a company.",
      icon: "fas fa-chart-line",    
    },  
    {
      name: "Industrial Optimization",
      desc: "A direct link to your hardware devices. We learn from previous mistakes and allow your system to make optimal decisions.",
      icon: "fas fa-server"
    },
  ];


 export const filters = {
    HARDWARE: "Hardware",
    CLOUD: "Cloud",
    HOME: "Home",
    BRAND: "Brand",
    OS: "OS",
  };

 export const projectsData = [
    {
      title: "Seed",
      projectInfo:
        "Kernel written in C/C++ made to integrate elle into core hardware devices",
      client: "Ambient Scientific",
      supports: "Arm, x86, GPX-10, Arduino ",
      technologies: "C, C++, Go",
      industry: "Hardware",
      date: "Present",
      url: {
        name: "Download",
        link: socials.github,
      },
      socialLinks: {
        facebook: socials.facebook,
        twitter: socials.twitter,
        github: socials.github,
        instagram: socials.instagram,
      },
      thumbImage: elle_chip,
      sliderImages: [
        
        
      ],
      categories: [filters.HARDWARE],
    },
    {
      title: "Genetic Learning",
      projectInfo:
        "We use a proprietary engine to create a digital organism that represents a function in real life. We produce Dna,representing the context of the space. so others can then use the context to control devices and personal automation ",
        client: "Testing...",
        supports: "Not Available to Public",
        technologies: "Go, JS, C",
        industry: "IoT",
        date: "Present",
        url: {
          name: "more info...",
          link: socials.github,
        },
      socialLinks: {
        facebook: socials.facebook,
        twitter: socials.twitter,
        github: socials.github,
        instagram: socials.instagram,
      },
      thumbImage: art_square,
      sliderImages: [
       
      ],
      categories: [filters.CLOUD, filters.OS],
    },
    {
        title: "Industry",
        projectInfo:
          "Since elle only needs vibration and sound input, we save on power and efficiency for larger projects such as mechanical automation. elle can easily diagnose, and fix issues with machinery hardware in real-time.",
          client: "Testing...",
          supports: "Not Available to Public  ",
          technologies: "Go, JS, C",
          industry: "IoT",
          date: "Present",
          url: {
            name: "more info...",
            link: socials.github,
          },
        socialLinks: {
          facebook: socials.facebook,
          twitter: socials.twitter,
          github: socials.github,
          instagram: socials.instagram,
        },
        thumbImage: cube,
        sliderImages: [
         
        ],
        categories: [filters.CLOUD, filters.HARDWARE],
      },
      {
        title: "Research",
        projectInfo:
          "Our research is the very foundation of the technology we produce. Through a vast array of mathematical and physics based techniques we are able to build unique models of real-time situations, solving and demonstrating value elle provides.",
        client: "Testing...",
        technologies: "FFT, DSP, Machine Learning, AI, Neural Networks, Quantum Computing",
        industry: "Nature Itself",
        date: "Present",
        url: {
          name: "more info...",
          link: "https://www.elledna.com",
        },
        socialLinks: {
          facebook: socials.facebook,
          twitter: socials.twitter,
          github: socials.github,
          instagram: socials.instagram,
        },
        thumbImage: elle_laptop,
        sliderImages: [
         
        ],
        categories: [filters.CLOUD, filters.OS, filters.BRAND],
      },
    {
      title: "Home Automation",
      projectInfo:
        "Through the creation of Dna structures based on behavior; elle can build a entire unique decentralized network around your home, helping family members and guest partake in the automation of your home--for the best home experience.",
      client: "Currently Alpha Testing",
      technologies: "Bluetooth, Radio, Wifi, Mobile, Web",
      industry: "IoT",
      date: "end of 2025",
      url: {
        name: "more info...",
        link: "https://www.elledna.com",
      },
      socialLinks: {
        facebook: socials.facebook,
        twitter: socials.twitter,
        github: socials.github,
        instagram: socials.instagram,
      },
      thumbImage: cube_home,
      sliderImages: [
      ],
      categories: [filters.HOME, filters.BRAND],
    },
    {
      title: "Network of Dna",
      projectInfo:
        "Our goal is the creation of a decentralized network of Dna structures that can be used to control any device in the world. We are currently working on a universal network design that will be able to connect any device to elle.",
      client: "The World",
      technologies: "Cloud, Mobile, Web, Hardware, Software",
      industry: "Network",
      date: "~2026",
      url: {
        name: "more info...",
        link: "https://www.elledna.com",
      },
      socialLinks: {
        facebook: socials.facebook,
        twitter: socials.twitter,
        github: socials.github,
        instagram: socials.instagram,
      },
      thumbImage: network_tree,
      sliderImages: [
       
      ],
      categories: [filters.CLOUD, filters.OS, filters.HARDWARE],
    },
    
  ];
