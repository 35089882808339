import React from "react";
import * as assets from "../info";
import ReactPlayer from "react-player";


const Resume = ({ classicHeader, darkTheme }) => {




  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            
            Team
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            because making stuff alone is hard.
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
            
          {/* My Education */}
          <div className="col-md-6">
            
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              founding members  
            </h2>
            {assets.TeamDetails.length > 0 &&
              assets.TeamDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <section
                        style={{
                            maxWidth: "200px",
                            margin: "0 auto",
                            background: "#fff",
                            padding: "2px 1px"
                        }}
                    >
                
                        <img
                            style={{ width: "100%", margin: "0px 0" }}
                            src={value.image}
                        />
    
                    </section>
  
                  
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.name}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.title}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                 {/*} <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.languages  }
                    </p>*/}
                  
                </div>
                
              ))}
          </div>
          {/* My Experience */}
       {/*  <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              associations
            </h2>
            {assets.AssociationsDetails.length > 0 &&
             assets.AssociationsDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4  " + (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                  <section
                        style={{
                            maxWidth: "250px",
                            margin: "0 auto",
                            background: "#fff",
                            padding: "2px 1px"
                        }}
                    >
                
                        <img
                            style={{ width: "100%", margin: "0px 0" }}
                            src={value.image}
                        />
    
                    </section>
                </div>
              ))}
          </div>*/}


           {<div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              media
            </h2>
            <div
                
                className={
                  "bg-white  rounded p-4 mb-4  " + (darkTheme ? "bg-dark" : "bg-white border")
                }
              >
            <section
                        style={{
                            maxWidth: "500px",
                            margin: "0 auto",
                            background: "#fff",
                            padding: "2px 1px"
                        }}
                    >
                        <ReactPlayer url="https://youtu.be/XN-XfS8xnKs?si=ko0fN93njZDxgbjS"  width='100%'  height='100%'/>
                    </section>
                    <section
                        style={{
                            maxWidth: "500px",
                            margin: "0 auto",
                            background: "#fff",
                            padding: "2px 1px"
                        }}
                    >
                        <ReactPlayer url="https://youtu.be/Pz5llmTnvc0?si=qBZopAeSs-4SZDez"  width='100%'  height='100%'/>
                    </section>
                    </div>
            
                <div
                
                  className={
                    "bg-white  rounded p-4 mb-4  " + (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                 
                  <section
                        style={{
                            maxWidth: "500px",
                            margin: "0 auto",
                            background: "#fff",
                            padding: "2px 1px"
                        }}
                    >
                        <ReactPlayer url="https://www.youtube.com/watch?v=54D7RnqWCuo"  width='100%'  height='100%'/>
                    </section>
                </div>
             
                 <div
                  
                    className={
                      "bg-white  rounded p-4 mb-4  " + (darkTheme ? "bg-dark" : "bg-white border")
                    }
                  >
                   
                    <section
                          style={{
                              maxWidth: "500px",
                              margin: "0 auto",
                              background: "#fff",
                              padding: "2px 1px"
                          }}
                      >
                          <ReactPlayer url="https://www.youtube.com/watch?v=3F_zoqzJyvQ"  width='100%'  height='100%'/>
                      </section>
                  </div>

              <div
                  
                    className={
                      "bg-white  rounded p-4 mb-4  " + (darkTheme ? "bg-dark" : "bg-white border")
                    }
                  >
                   
                    <section
                          style={{
                              maxWidth: "500px",
                              margin: "0 auto",
                              background: "#fff",
                              padding: "2px 1px"
                          }}
                      >
                          <ReactPlayer url="https://www.youtube.com/watch?v=1XiQN5sYlaQ"  width='100%'  height='100%'/>
                      </section>
                  </div>
             
          </div>}










        </div>
        {/* tech stack*/}




<div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            
            Tech Stack
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
           the how.
           <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
    </div>
    <div className="col-lg-7 col-xl-8 text-center text-lg-start">
        <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
        <span className="text-primary">Our Team</span> integrates our technologies with software and hardware. Our goal is to build a platform from embedded Systems: including Arm and X86. 
                Our Cloud Services offer a deep integration with other types of technologies--such as system wide updates. 
        </p>
    </div>
        <div className="row gx-5">
          {assets.techStack.length > 0 &&
            assets.techStack.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
      {/*  <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            Download CV
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
                </div>*/}
     {/* <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
<div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            
            {"%"}
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
           goals.
           <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          
          </p>
        </div>
    </div>
          

{/* <div className="row gx-5">
          {assets.buildGoals.length > 0 &&
            assets.buildGoals.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>  
        
        **/}







      </div>


      
    
    </section>
  );
};

export default Resume;
